import { Card, Col, Image, Row } from 'react-bootstrap';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

const OnlyText = ({ page }) => {
  return (
    <Row className={'justify-content-center mb-3'}>
      <Col xs={12}>
        <Card
          className=" d-flex "
          style={{ height: '80vh', overflow: 'hidden' }}
        >
          <Card.Body
            className="d-flex p-3 justify-content-center"
            style={{ maxHeight: '80vh' }}
          >
            <Row className={'align-items-center mt-2 mb-2 '}>
              <Col>
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.text
                  }}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

OnlyText.propTypes = {
  page: PropTypes.object.isRequired
};

OnlyText.defaultTypes = {
  page: {}
};

export default OnlyText;
