import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import locale from '../../../../helpers/locale';
import PropTypes from 'prop-types';

const Clock = React.memo(({ onNewDay, hijriEn }) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      const newDate = new Date();

      const newDateString = newDate.toDateString();
      const currentDateString = currentDateTime.toDateString();

      if (newDateString !== currentDateString) {
        onNewDay(newDate);
      }

      setCurrentDateTime(newDate);
    }, 1000);

    return () => clearInterval(timerId);
  }, [currentDateTime]);

  const formatDate = date =>
    date.toLocaleDateString(locale.locale2, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

  const formatTime = date => {
    let timeString = date.toLocaleTimeString();
    //timeString = '23:59:59';
    return timeString
      .split('')
      .map((char, index) =>
        index === 7
          ? `<span style="width: 50px;text-align: right;display: inline-block;padding: 0rem 0.5rem;">${char}</span>`
          : `<span style="width: auto;text-align: center;display: inline-block;padding: 0rem 0.5rem;">${char}</span>`
      )
      .join('');
  };

  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h1 className={'text-center fw-light'}>
                <span>{formatDate(currentDateTime)}</span>
                {hijriEn && <div>{hijriEn}</div>}
              </h1>
              <h1
                className={
                  'align-items-center fw-medium d-none d-xxl-block text-center'
                }
                style={{ fontSize: '12rem' }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatTime(currentDateTime)
                  }}
                ></div>
              </h1>
              <h1 className={'fw-medium text-center d-block d-xxl-none'}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatTime(currentDateTime)
                  }}
                ></div>
              </h1>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
});

Clock.propTypes = {
  onNewDay: PropTypes.func.isRequired,
  hijriEn: PropTypes.string
};

Clock.defaultProps = {
  onNewDay: () => {},
  hijriEn: null
};

export default Clock;
